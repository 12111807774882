import { GET_CLINICS, VIEW_ALL_CART_ITEMS } from "../../constants/treatment/ViewAllCart"

const initialState = {
    treatmentCartItems: [],
    totalPrice: 0,
    totalDeposit: 0
}

export const ViewAllTreatmentCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_ALL_CART_ITEMS:


            const total = action.payload?.data?.items.reduce((accumulator, object) => {
                return accumulator + object['treatment_price'];
            }, 0);
            const totalDeposit = action.payload?.data?.items.reduce((accumulator, object) => {
                return accumulator + object['treatment_deposit'];
            }, 0);
            return {
                ...state,
                treatmentCartItems: action.payload?.data?.items,
                totalPrice: total,
                totalDeposit: totalDeposit
            }

        default:
            return state
    }

}

const intialClinicsState = {
    clinicsDataItems: null
}

export const GetAllClinicsDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLINICS:

            return {
                ...state,
                clinicsDataItems: action.payload?.data?.items
            }

        default:
            return state
    }
}