import { CLEAR_TREATEMENT_LIST, GET_TREATMENT_LISTING_CONST } from "../../constants"

const initialState = {
    treatmentsList: [],
    totalTreatmentsCount: 0,
    loadedTreatmentsCount: 0,
    treatmentListPage: 1,
}
export const GetTreatmentListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TREATMENT_LISTING_CONST:
            let temp = (state?.treatmentsList).concat(action.payload.data?.items)
            return {
                ...state,
                treatmentsList: temp,
                totalTreatmentsCount: action.payload.data.total,
                loadedTreatmentsCount: temp.length,
                treatmentListPage: temp.length > 12
                    ? parseInt(state?.totalTreatmentsCount / state?.loadedTreatmentsCount)
                    : state.treatmentListPage,
            };

        case CLEAR_TREATEMENT_LIST:
            return {
                ...state,
                treatmentsList: [],
                totalTreatmentsCount: 0,
                loadedTreatmentsCount: 0,
                treatmentListPage: 1,
            }
        default:
            return state
    }
}  