import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './app.scss';
// import AcademyHomePage from "./pages/AcademyHomePage";
import { aboutUs, clinicCart, contactUs, errorPage, indexPattern, privacyPolicy, termsConditions, treatmentDetails, treatmentListing } from "./Routes";
// import CourseListing from "./pages/listing/CourseListing";
// import CourseDetails from "./pages/details/course-details/CourseDetails";
import Cookies from "js-cookie";
import { lazy, Suspense, useEffect, useState } from "react";
// import AcademyCart from "./pages/cart/academy-cart/AcademyCart";
// import Dashboard from "./pages/dashboard/Dashboard";
// import CourseModules from "./pages/course-modules/CourseModules";
// import CourseExam from "./pages/course-exam/CourseExam";

// import MyBookings from "./pages/my-bookings/MyBookings";
import { useSelector } from "react-redux";
// import { toast, ToastContainer } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { socket } from './socket';

import { v4 as uuidv4 } from 'uuid';
import Loader from "./components/loader/Loader";

const ClinicCart = lazy(() => import("./pages/cart/clinic-cart/ClinicCart"))
const ClinicHomePage = lazy(() => import("./pages/ClinicHomePage"))
const TreatmentDetails = lazy(() => import("./pages/details/treatment-details/TreatmentDetails"))
const TreatmentListing = lazy(() => import("./pages/listing/TreatmentListing"))
const ErrorPage = lazy(() => import("./components/error-page/ErrorPage"));
const NotificationPopup = lazy(() => import("./components/notification-popup/NotificationPopup"));
const Aboutus = lazy(() => import("./pages/cms-pages/about-us/Aboutus"));
const PrivacyPolicy = lazy(() => import("./pages/cms-pages/privacy-policy/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/cms-pages/terms-conditions/TermsConditions"));
const ContactUs = lazy(() => import("./pages/conatct-us/ContactUs"));

export let cookie_auth
const routerParams = {};

if (process.env.PUBLIC_URL) {
  routerParams["basename"] = process.env.PUBLIC_URL;
}

export function generateRandomString() {
  // let result = '';
  // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // const charactersLength = characters.length;
  // let counter = 0;
  // while (counter < length) {
  //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   counter += 1;
  // }
  // return result;
  return uuidv4();
}

if (sessionStorage.getItem('sessionString') === null) {
  sessionStorage.setItem('sessionString', generateRandomString())
}


function App() {

  const currentURL = window.location.href;
  const urlObject = new URL(currentURL);
  const domain = urlObject.hostname;

  const [isConnected, setIsConnected] = useState('');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
      setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('connect_error', (err) => {
    })

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  useEffect(() => {
    socket.on('broadcast-notice', (event) => {
      if (event.domain == domain && event.mode == process.env.REACT_APP_ORDER_POPUP_MODE && (event.type == "course-purchase" || event.type == "treatment-purchase")) {
        toast(<NotificationPopup eventData={event?.data} />, {
          position: "bottom-left",
          autoClose: 5000,
          className: "order",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    });
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('broadcast-notice');
    };
  }, [isConnected])

  cookie_auth = useSelector((state) => state?.AuthDataReducer?.checkAuth)

  useEffect(() => {

  }, [Cookies.get('token', { domain: `.${window.location?.hostname}` }), cookie_auth, Cookies.get("faces_access_token")])
  return (
    <>
      <Suspense fallback={<Loader isActive={true} />}>
        <Router {...routerParams}>
          <Routes>
            <Route
              exact
              path={indexPattern}
              element={
                <ClinicHomePage />
              }
            />
            {/* <Route
            exact
            path={academyHome}
            element={
              <AcademyHomePage />
            }
          /> */}
            {/* <Route
            exact
            path={courseListing}
            element={
              <CourseListing />
            }
          /> */}
            {/* <Route
            exact
            path={courseDetails}
            element={
              <CourseDetails />
            }
          /> */}
            {/* <Route
            exact
            path={academyCart}
            element={
              <AcademyCart />
            }
          /> */}
            {/* <Route
            exact
            path={dashboard}
            element={
              <Dashboard auth={cookie_auth} />
            }
          /> */}
            {/* <Route
            exact
            path={courseModules}
            element={
              <CourseModules auth={cookie_auth} />
            }
          /> */}
            {/* <Route
            exact
            path={courseExam}
            element={
              <CourseExam auth={cookie_auth} />
            }
          /> */}
            {/* <Route
            exact
            path={clinicHome}
            element={
              <ClinicHomePage />
            }
          /> */}
            <Route
              exact
              path={treatmentListing}
              element={
                <TreatmentListing />
              }
            />
            <Route
              exact
              path={treatmentDetails}
              element={
                <TreatmentDetails />
              }
            />
            <Route
              exact
              path={contactUs}
              element={
                <ContactUs />
              }
            />
            <Route
              exact
              path={clinicCart}
              element={
                <ClinicCart />
              }
            />
            {/* <Route
            exact
            path={myBookings}
            element={
              <MyBookings />
            }
          /> */}
            <Route
              exact
              path={errorPage}
              element={
                <ErrorPage />
              }
            />
            <Route
              exact
              path={aboutUs}
              element={
                <Aboutus />
              }
            />
            <Route
              exact
              path={privacyPolicy}
              element={
                <PrivacyPolicy />
              }
            />
            <Route
              exact
              path={termsConditions}
              element={
                <TermsConditions />
              }
            />
          </Routes>
        </Router>
      </Suspense>
      <Toaster
        position={screenWidth>576?"top-right":"top-center"}
        reverseOrder={false}
        gutter={0}
        toastOptions={{
          duration:1000,
          style: {
            padding: '15px 30px',
            color: '#000000',
          },
        }}
      />
    </>
  );
}

export default App;
