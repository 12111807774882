import { combineReducers } from "redux";
import { GetTreatmentListReducer } from './treatment-listing'
import { ViewAllTreatmentCartReducer, GetAllClinicsDataReducer } from './treatment/ViewAllCart'
import { GetDateTimeSlotsReducer } from './treatment/DateTimeslots'
import { BookingIdDetailsReducer } from './treatment/BookingPayment'
import { GetCourseListReducer } from './courses/Listing'
import { ViewAllCourseTreatmentCartReducer } from './courses/ViewAllCourseCart'
import { AuthDataReducer } from './auth/AuthData'
import { AcademyHomeReducer } from './home-page/Academy'
import { BookingCourseDetailsReducer } from './courses/BookingPayment'
const rootReducer = combineReducers({
    GetTreatmentListReducer,
    ViewAllTreatmentCartReducer,
    GetAllClinicsDataReducer,
    GetDateTimeSlotsReducer,
    BookingIdDetailsReducer,
    GetCourseListReducer,
    ViewAllCourseTreatmentCartReducer,
    AuthDataReducer,
    AcademyHomeReducer,
    BookingCourseDetailsReducer
});

export default rootReducer;
